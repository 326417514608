// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as $$Date from "../../../libs/Date.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as Api_BlogPost from "../../../api/blog-posts/Api_BlogPost.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as BlogPostNavbar from "../common/navbar/BlogPostNavbar.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as BlogPostsOptions from "./BlogPostsOptions.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as BlogPostsDashboardActionBar from "./BlogPostsDashboardActionBar.res.js";
import * as DashboardDeleteConfirmationModalCss from "../../../styleguide/dashboard/components/modals/deleteConfirmation/DashboardDeleteConfirmationModalCss.res.js";

var initialState_sortBy = {
  NAME: "CreatedAt",
  VAL: "Desc"
};

var initialState_selection = ID.$$Set.make();

var initialState_categoryFilter = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingBlogPosts",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  categoryFilter: initialState_categoryFilter,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false,
  openDestroyConfirm: false,
  currentBlogPostId: undefined
};

function BlogPostsDashboard$default(props) {
  var categories = props.categories;
  var container = React.useRef(null);
  var searchBlogPosts = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchBlogPosts" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_BlogPost.fetchBlogPostsDashboard(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.categoryFilter)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedBlogPostsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "BlogPostsDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "BlogPostsDashboard.default"
                                          }, "BlogPostsDashboard::FetchBlogPosts::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailBlogPostsFetch");
                                    }));
                            })
                        };
              case "FailBlogPostsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedBlogPostsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var blogPosts = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: blogPosts.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(blogPosts, (function (blogPost) {
                                          return blogPost.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            }
                          };
                  }
              case "PublishInBatch" :
                  var blogPosts$1 = state.status;
                  if (typeof blogPosts$1 !== "object") {
                    return "NoUpdate";
                  }
                  var blogPosts$2 = blogPosts$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Publishing",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var blogPostIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (blogPost) {
                                                          return Caml_obj.equal(id, blogPost.id);
                                                        }), __x);
                                          })(blogPosts$2);
                                      if (match !== undefined && !match.approved) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_BlogPost.publishBlogPostsInBatch(blogPostIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedPublishInBatch",
                                                    _0: blogPostIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "BlogPostsDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "BlogPostsDashboard.default"
                                          }, "BlogPostsDashboard" + "::PublishInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "UnpublishInBatch" :
                  var blogPosts$3 = state.status;
                  if (typeof blogPosts$3 !== "object") {
                    return "NoUpdate";
                  }
                  var blogPosts$4 = blogPosts$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Unpublishing",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var blogPostIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (blogPost) {
                                                          return Caml_obj.equal(id, blogPost.id);
                                                        }), __x);
                                          })(blogPosts$4);
                                      if (match !== undefined && match.approved) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_BlogPost.unpublishBlogPostsInBatch(blogPostIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedUnpublishInBatch",
                                                    _0: blogPostIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "BlogPostsDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "BlogPostsDashboard.default"
                                          }, "BlogPostsDashboard" + "::UnpublishInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: false,
                            currentBlogPostId: undefined
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedBlogPostsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.blogPosts
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingBlogPosts") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingBlogPosts",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchBlogPosts");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingBlogPosts") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "Category") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "Category" && match$6.VAL !== "Desc" ? ({
                          NAME: "Category",
                          VAL: "Desc"
                        }) : ({
                          NAME: "Category",
                          VAL: "Asc"
                        });
                  } else if (sort === "Title") {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "Title" && match$7.VAL === "Desc" ? ({
                          NAME: "Title",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Title",
                          VAL: "Desc"
                        });
                  } else if (sort === "PublishedAt") {
                    var match$8 = state.sortBy;
                    tmp = typeof match$8 === "object" && match$8.NAME === "PublishedAt" && match$8.VAL === "Desc" ? ({
                          NAME: "PublishedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "PublishedAt",
                          VAL: "Desc"
                        });
                  } else {
                    var match$9 = state.sortBy;
                    tmp = typeof match$9 === "object" && match$9.NAME === "CreatedAt" && match$9.VAL === "Desc" ? ({
                          NAME: "CreatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "CreatedAt",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPosts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPosts");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: searchBlogPosts
                        };
              case "PerformSearch" :
                  var match$10 = state.status;
                  if (typeof match$10 !== "object" && match$10 === "FetchingBlogPosts") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingBlogPosts",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchBlogPosts");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$11 = state.batchUpdate;
                  if (match$11 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            }
                          };
                  }
              case "ToggleCategoryFilter" :
                  var categoryId = action._0;
                  var match$12 = state.status;
                  if (typeof match$12 !== "object" && match$12 === "FetchingBlogPosts") {
                    return "NoUpdate";
                  }
                  var categoryFilter = Belt_Set.has(state.categoryFilter, categoryId) ? Belt_Set.remove(state.categoryFilter, categoryId) : Belt_Set.add(state.categoryFilter, categoryId);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPosts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPosts");
                            })
                        };
              case "UpdatePublished" :
                  var action$1 = action._1;
                  var blogPostId = action._0;
                  var match$13 = state.status;
                  var match$14 = state.batchUpdate;
                  var match$15 = Belt_Map.get(state.entityUpdates, blogPostId);
                  if (typeof match$13 !== "object") {
                    match$13 === "FetchingBlogPosts";
                  } else if (match$14 === undefined && match$15 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$13._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, blogPostId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Publish" ? true : false;
                                        return {
                                                id: x.id,
                                                title: x.title,
                                                createdAt: x.createdAt,
                                                publishedAt: x.publishedAt,
                                                approved: tmp,
                                                slug: x.slug,
                                                category: x.category
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              categoryFilter: state.categoryFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, blogPostId, {
                                    TAG: "PublishUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Publish") {
                                  return $$Promise.wait(Api_BlogPost.publishBlogPost(blogPostId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedPublishedUpdate",
                                                              _0: blogPostId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "BlogPostsDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "BlogPostsDashboard.default"
                                                    }, "BlogPostsDashboard" + "::UpdateStatus::Publish::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailPublishedUpdate",
                                                      _0: blogPostId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_BlogPost.unpublishBlogPost(blogPostId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedPublishedUpdate",
                                                              _0: blogPostId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "BlogPostsDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "BlogPostsDashboard.default"
                                                    }, "BlogPostsDashboard" + "::UpdateStatus::Unpublish::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailPublishedUpdate",
                                                      _0: blogPostId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedPublishedUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          }
                        };
              case "FailPublishedUpdate" :
                  var blogPostId$1 = action._0;
                  var match$16 = state.status;
                  var match$17 = Belt_Map.get(state.entityUpdates, blogPostId$1);
                  if (typeof match$16 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$17 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$17._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$16._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, blogPostId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Publish" ? true : false;
                                      return {
                                              id: x.id,
                                              title: x.title,
                                              createdAt: x.createdAt,
                                              publishedAt: x.publishedAt,
                                              approved: tmp,
                                              slug: x.slug,
                                              category: x.category
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, blogPostId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentBlogPostId: state.currentBlogPostId
                          }
                        };
              case "SucceedPublishInBatch" :
                  var ids = action._0;
                  var blogPosts$5 = state.status;
                  if (typeof blogPosts$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(blogPosts$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  title: x.title,
                                                  createdAt: x.createdAt,
                                                  publishedAt: x.publishedAt,
                                                  approved: true,
                                                  slug: x.slug,
                                                  category: x.category
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            }
                          };
                  }
              case "SucceedUnpublishInBatch" :
                  var ids$1 = action._0;
                  var blogPosts$6 = state.status;
                  if (typeof blogPosts$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(blogPosts$6._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  title: x.title,
                                                  createdAt: x.createdAt,
                                                  publishedAt: x.publishedAt,
                                                  approved: false,
                                                  slug: x.slug,
                                                  category: x.category
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentBlogPostId: state.currentBlogPostId
                            }
                          };
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: true,
                            currentBlogPostId: Caml_option.some(action._0)
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchBlogPosts");
        }), []);
  var len = categories.length;
  var tmp;
  if (len !== 1 && len !== 0) {
    var x = Belt_Set.size(state.categoryFilter);
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardFilterCss.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " Categories" : "1 Category"
                    ) : "All Categories"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardFilterCss.dropdownBody,
                  children: Belt_Array.map(categories, (function (category) {
                          var id = "blogPost-categories-filter--category-" + ID.toString(category.id);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: Belt_Set.has(state.categoryFilter, category.id),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleCategoryFilter",
                                                        _0: category.id
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: category.title,
                                              className: DashboardFilterCss.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: DashboardFilterCss.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp = null;
  }
  var blogPosts = state.status;
  var tmp$1;
  if (typeof blogPosts !== "object") {
    tmp$1 = false;
  } else {
    var blogPosts$1 = blogPosts._0;
    tmp$1 = Belt_Set.size(state.selection) === blogPosts$1.length && blogPosts$1.length !== 0;
  }
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var blogPosts$2 = state.status;
  var tmp$2;
  if (typeof blogPosts$2 !== "object") {
    tmp$2 = blogPosts$2 === "FetchingBlogPosts" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardTableCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardTableCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var blogPosts$3 = blogPosts$2._0;
    tmp$2 = blogPosts$3.length !== 0 ? Belt_Array.mapWithIndex(blogPosts$3, (function (index, blogPost) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Checkbox.make, {
                                        id: "blogPost--" + (ID.toString(blogPost.id) + "--selected"),
                                        size: "SM",
                                        checked: Belt_Set.has(state.selection, blogPost.id),
                                        onChange: (function (param) {
                                            dispatch({
                                                  TAG: "ToggleSelectionOfItem",
                                                  _0: blogPost.id
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: blogPost.title,
                                        className: DashboardTableCss.tableLink,
                                        href: Routes_BlogPost.Dashboard.edit(blogPost.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: blogPost.category
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: $$Date.Naive.format(blogPost.publishedAt, "MM/dd/yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardFilterCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: DashboardFilterCss.dropdownBodyLink,
                                                                    href: Routes_BlogPost.Dashboard.edit(blogPost.id)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: DashboardFilterCss.dropdownBodyLink,
                                                                    href: Routes_BlogPost.show(blogPost.slug)
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardTableCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No blog posts were found."
                })
          });
  }
  var blogPosts$4 = state.status;
  var tmp$3;
  if (typeof blogPosts$4 !== "object") {
    tmp$3 = null;
  } else {
    var blogPosts$5 = blogPosts$4._0;
    tmp$3 = JsxRuntime.jsx(BlogPostsDashboardActionBar.make, {
          selection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (blogPost) {
                                                    return Caml_obj.equal(id, blogPost.id);
                                                  }), __x);
                                    })(blogPosts$5), (function (blogPost) {
                                    return blogPost.approved;
                                  }));
                    })), [], (function (acc, approved) {
                  if (approved !== undefined) {
                    return Belt_Array.concat(acc, [approved]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          publish: (function (param) {
              dispatch("PublishInBatch");
            }),
          unpublish: (function (param) {
              dispatch("UnpublishInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: DashboardDeleteConfirmationModalCss.text
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch("CloseDestroyConfirm");
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var blogPostId = state.currentBlogPostId;
                                                  if (blogPostId !== undefined) {
                                                    return $$Promise.wait(Api_BlogPost.destroyBlogPost(Caml_option.valFromOption(blogPostId)), (function (x) {
                                                                  if (x.TAG === "Ok") {
                                                                    return Url.visit(Routes_BlogPost.Dashboard.index);
                                                                  } else {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "BlogPostsDashboard",
                                                                                subModulePath: /* [] */0,
                                                                                value: "default",
                                                                                fullPath: "BlogPostsDashboard.default"
                                                                              }, "DestroyBlogPost::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                }));
                                                  } else {
                                                    return dispatch("CloseDestroyConfirm");
                                                  }
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: DashboardDeleteConfirmationModalCss.buttons
                                    })
                              ],
                              className: DashboardDeleteConfirmationModalCss.dialog
                            }),
                        className: DashboardDeleteConfirmationModalCss.overlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                JsxRuntime.jsx(BlogPostNavbar.make, {
                      selectedCategory: "BlogPosts"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Blog Posts"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "blogPosts-search",
                                              value: state.search,
                                              placeholder: "Search blog posts",
                                              inputClassName: DashboardFilterCss.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        tmp,
                                        JsxRuntime.jsx(BlogPostsOptions.make, {})
                                      ],
                                      className: DashboardIndexCss.headerControls
                                    })
                              ],
                              className: DashboardIndexCss.header
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs(Table.make, {
                                    className: DashboardTableCss.table,
                                    children: [
                                      JsxRuntime.jsx(TableHeader.make, {
                                            children: JsxRuntime.jsxs(TableRow.make, {
                                                  children: [
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Checkbox.make, {
                                                                id: "blogPost--all--selected",
                                                                size: "SM",
                                                                checked: tmp$1,
                                                                onChange: (function (param) {
                                                                    dispatch("ToggleSelectionOfAllItems");
                                                                  })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "SortBy",
                                                                          _0: "Title"
                                                                        });
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Blog Post",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "SortBy",
                                                                          _0: "Category"
                                                                        });
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Category",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$2 === "object" && match$2.NAME === "Category" ? match$2.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "SortBy",
                                                                          _0: "PublishedAt"
                                                                        });
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Published At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$3 === "object" && match$3.NAME === "PublishedAt" ? match$3.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: ""
                                                        })
                                                  ]
                                                })
                                          }),
                                      JsxRuntime.jsx(TableBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  }),
                              className: DashboardTableCss.tableWrapper
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: DashboardTableCss.pagination
                              }) : null
                      ]
                    }),
                tmp$3
              ]
            });
}

var Css;

var Filters;

var TableCss;

var Modal;

var $$default = BlogPostsDashboard$default;

export {
  Css ,
  Filters ,
  TableCss ,
  Modal ,
  initialState ,
  $$default as default,
}
/* initialState Not a pure module */
