// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var overlay = Cx.cx([
      "fixed",
      "top-0",
      "bottom-0",
      "right-0",
      "left-0",
      "flex",
      "items-center",
      "justify-center",
      "text-white",
      "bg-gray-800/50",
      "z-1000"
    ]);

var dialog = Cx.cx([
      "w-80",
      "h-40",
      "bg-white",
      "rounded-lg",
      "p-5",
      "flex",
      "flex-col",
      "justify-between"
    ]);

var text = Cx.cx([
      "font-bold",
      "text-black",
      "text-2xl"
    ]);

var buttons = Cx.cx([
      "flex",
      "justify-between"
    ]);

var message = Cx.cx([
      "text-sm",
      "text-gray-500"
    ]);

export {
  overlay ,
  dialog ,
  text ,
  buttons ,
  message ,
}
/* overlay Not a pure module */
